/*=========================================================================================
    File Name: _footer.scss
    Description: Footer styles
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


.footer-sticky {
    .the-footer {
        position: fixed;
        background: $white;
        bottom: 0;
        width: calc(100% - 260px);
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);
        z-index: 40000;
    }

    .content-area-reduced{
        .the-footer{
            width: calc(100% - 80px);
        }
    }

    .content-area-full{
        .the-footer{
            width: 100%;
        }
    }

    .router-view{
        padding-bottom: 5rem;
    }
}
.footer-hidden{
    .the-footer{
        display: none;
    }
}
.the-footer{
    padding: 1rem 2.2rem;
}
