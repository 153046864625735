/*=========================================================================================
    File Name: _charts.scss
    Description: Styles for charts.
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


.echarts{
    width: 100% !important;
}