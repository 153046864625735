/*=========================================================================================
    File Name: _customClasses.scss
    Description: partial- this file containes custom helper classes
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


//////////////////////////////////////////////////////////////////////////
//  GRID-LAYOUT CLASSES
//////////////////////////////////////////////////////////////////////////

.vx-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;
    // overflow: hidden;
    // @apply flex flex-wrap -mx-4;


    & > .vx-col {
        padding: 0 1rem;
    }

    &.match-height > .vx-col {
        display: flex;
    }

    &.no-gutter {
        margin: 0;

        & > .vx-col {
            padding: 0;
        }
    }
}



//////////////////////////////////////////////////////////////////////////
// MAIN COLORS
//////////////////////////////////////////////////////////////////////////

// text classes
.text-primary {
    color: rgba(var(--vs-primary),1) !important;
}


.text-success {
    color: rgba(var(--vs-success),1) !important;
}

.text-danger {
    color: rgba(var(--vs-danger),1) !important;
}

.text-warning {
    color: rgba(var(--vs-warning),1) !important;
}

.text-dark {
    color: rgba(var(--vs-dark),1) !important;
}


// background classes

.bg-primary {
    background-color: rgba(var(--vs-primary),1) !important;
}


.bg-success {
    background-color: rgba(var(--vs-success),1) !important;
}

.bg-danger {
    background-color: rgba(var(--vs-danger),1) !important;
}

.bg-warning {
    background-color: rgba(var(--vs-warning),1) !important;
}

.bg-dark {
    background-color: rgba(var(--vs-dark),1) !important;
}


// border classes

.border-primary {
    border-color: rgba(var(--vs-primary),1) !important;
}

.border-success {
    border-color: rgba(var(--vs-success),1) !important;
}

.border-danger {
    border-color: rgba(var(--vs-danger),1) !important;
}

.border-warning {
    border-color: rgba(var(--vs-warning),1) !important;
}

.border-dark {
    border-color: rgba(var(--vs-dark),1) !important;
}


// hover background classes

.hover\:bg-primary {
    &:hover {
        background-color: rgba(var(--vs-primary),1) !important;
    }
}


.hover\:bg-success {
    &:hover {
        background-color: rgba(var(--vs-success),1) !important;
    }
}

.hover\:bg-danger {
    &:hover {
        background-color: rgba(var(--vs-danger),1) !important;
    }
}

.hover\:bg-warning {
    &:hover {
        background-color: rgba(var(--vs-warning),1) !important;
    }
}

.hover\:bg-dark {
    &:hover {
        background-color: rgba(var(--vs-dark),1) !important;
    }
}


// hover text colors
.hover\:text-primary {
    &:hover {
        color: rgba(var(--vs-primary),1) !important;
    }
}


.hover\:text-success {
    &:hover {
        color: rgba(var(--vs-success),1) !important;
    }
}

.hover\:text-danger {
    &:hover {
        color: rgba(var(--vs-danger),1) !important;
    }
}

.hover\:text-warning {
    &:hover {
        color: rgba(var(--vs-warning),1) !important;
    }
}

.hover\:text-dark {
    &:hover {
        color: rgba(var(--vs-dark),1) !important;
    }
}

// Gradient colors
.bg-primary-gradient {
    background: linear-gradient(118deg, rgba(var(--vs-primary),1), rgba(var(--vs-primary),.7)) !important;
}

.bg-success-gradient {
    background: linear-gradient(118deg, rgba(var(--vs-success),1), rgba(var(--vs-success),.7)) !important;
}

.bg-danger-gradient {
    background: linear-gradient(118deg, rgba(var(--vs-danger),1), rgba(var(--vs-danger),.7)) !important;
}

.bg-warning-gradient {
    background: linear-gradient(118deg, rgba(var(--vs-warning),1), rgba(var(--vs-warning),.7)) !important;
}

.bg-dark-gradient {
    background: linear-gradient(118deg, rgba(var(--vs-dark),1), rgba(var(--vs-dark),.7)) !important;
}


//////////////////////////////////////////////////////////////////////////
// BUTTON
//////////////////////////////////////////////////////////////////////////

.btn-group {
  button {
    padding: 1rem 1.25rem !important;
  }

  :not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  :not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.btn-group-vertical {

  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  button {
    padding: 1rem 1.25rem !important;
    width: 100%;
  }

  :not(:first-child) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  :not(:last-child) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

//////////////////////////////////////////////////////////////////////////
// CHIP
//////////////////////////////////////////////////////////////////////////

.con-vs-chip {
  &.number {
    padding-right: unset;
    min-height: 20px;
    min-width: 20px;

    .vs-chip--text {
      margin-left: unset;
      font-weight: 600;
    }
  }
}



//////////////////////////////////////////////////////////////////////////
// TYPOGRAPHY
//////////////////////////////////////////////////////////////////////////

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-big{
    font-size: 4rem;
}

.text-color-base {
    color: $content-color;
}



//////////////////////////////////////////////////////////////////////////
// BLUR
//////////////////////////////////////////////////////////////////////////

.blur-1 {
    filter: blur(1px);
}
.blur-2 {
    filter: blur(2px);
}
.blur-3 {
    filter: blur(3px);
}



//////////////////////////////////////////////////////////////////////////
// SIDEBAR
//////////////////////////////////////////////////////////////////////////

.sidebar-spacer {
    width: calc(100% - 260px);
    margin-left: 260px;
}

.background-absolute {
  .vs-sidebar--background {
    position: absolute;
  }
}

.sidebar-spacer-with-margin {
    width: calc(100% - 260px - 2.2rem);
    margin-left: calc(260px + 2.2rem);
}

.sidebar-spacer--wide {
    width: calc(100% - 400px);
    margin-left: 400px;
}

// Create full width sidebar
.full-vs-sidebar {
    .vs-sidebar {
        max-width: calc(100% - 260px);
        margin-left: 260px;
    }
}

@media only screen and (max-width: 992px) {
    .full-vs-sidebar {
        .vs-sidebar {
            max-width: 100%;
            margin-left: 0;
        }
    }
}


//////////////////////////////////////////////////////////////////////////
// COMPONENT HAVING INPUT COMPONENT
//////////////////////////////////////////////////////////////////////////

.vs-input-shadow-drop {
    input {
        box-shadow: 0 2px 8px 0 rgba(0,0,0, 0.14);
    }
}

// vs-input
.vs-input-no-border {
    .vs-input--input {
        border: none !important;

        &:focus {
            border: none !important;
        }
    }
}

.vs-input-no-shdow-focus {
    .vs-input--input {
        &:focus {
            box-shadow: none !important;
        }
    }
}


// vs-select
.vs-select-no-border {
    .vs-select--input {
        border: none !important;
    }
}



//////////////////////////////////////////////////////////////////////////
// OTHER
//////////////////////////////////////////////////////////////////////////

.responsive {
    width: 100%;
    height: auto;
}

ul.bordered-items {
    & > li:not(:last-of-type):not([class*='shadow']) {
        border-bottom: 1px solid $grey-light;
    }
}



//////////////////////////////////////////////////////////////////////////
// USERS LIST
//////////////////////////////////////////////////////////////////////////
.user-list {
    display: flex;

    & .con-vs-avatar {
        transition: .3s;
        &:hover {
            transform: translateY(-5px) scale(1.07);
            box-shadow: 0 14px 24px rgba(62, 57, 107, 0.2);
            // transform: translateY(-5px);
            z-index: 999;
        }
    }
}

// DROPDOWN
// Remove padding and border from default dropdown - Used in TheNavbar
.dropdown-custom {
    .vs-dropdown--custom {
            padding: 0 !important;
            border: 0;
            overflow: hidden;
            border-radius: .5rem;
            box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08) !important;
            // @apply shadow-lg;
    }
    .vs-dropdown--menu--after {
        background: rgba(var(--vs-primary),1) !important;
        right: 1.6rem !important;
    }
}


//////////////////////////////////////////////////////////////////////////
// UNIVERSAL CLASSES FOR LIGHT & DARK THEME
//////////////////////////////////////////////////////////////////////////

.d-theme-dark-bg {
  background-color: $white;
}

.d-theme-border-grey-light {
  border-color: #dae1e7;
}

.d-theme-text-inverse {
  color: $white;
}

.theme-dark {
    .d-theme-dark-bg {
        background-color: $theme-dark-bg;
    }

     .d-theme-input-dark-bg {
        input {
            background-color: $theme-dark-bg;
        }
    }

    .d-theme-heading-color{
        color: $headings-color;
    }
    .d-theme-text-inverse{
        color: $content-color;
    }

    .d-theme-border-grey-light {
	    border-color: $theme-dark-border-color;
	  }
}
