/*=========================================================================================
    File Name: _tailwindFixes.scss
    Description: partial- Tailwind Fixes
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


// button fix
button:focus {
    outline: none;
}