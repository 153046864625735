/*=========================================================================================
    File Name: _formWizard.scss
    Description: Styles for form wizard externsion.
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


.vue-form-wizard.md {
    .wizard-navigation {
        .wizard-progress-with-circle{
            top: 33px !important;
        }    

        .wizard-nav {
            .wizard-icon-circle{
                border: 3px solid #cccccc;
                width: 55px;
                height: 55px;

                .wizard-icon{
                    font-size: 1.5rem;
                }
            }
            
            .stepTitle{
                color: #626262;
            }
        }
    }
}