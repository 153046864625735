/*=========================================================================================
    File Name: _transitions.scss
    Description: Transition styles
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


///////////////////////////////////////////////////////////
// transition-group : list;
///////////////////////////////////////////////////////////
.list-leave-active {
    position: absolute;
}

.list-enter,
.list-leave-to{
    opacity: 0;
    transform: translateX(30px);
}


///////////////////////////////////////////////////////////
// transition-group : list-enter-up;
///////////////////////////////////////////////////////////
.list-enter-up-leave-active {
  transition: none !important;
}

.list-enter-up-enter {
  opacity: 0;
  transform: translateY(30px);
}
